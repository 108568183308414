import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import setpointApolloClient from '@/lib/appsync/setpoint';
import setpointApolloV3Client from '@/lib/appsync/setpointv3';
import getSetpointData from '@/graphql/setpoints/queries/getSetpointData.graphql';
import getSetpointV3CycleTable from '@/graphql/setpoints/queries/getSetpointV3CycleTable.graphql';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'setpointModule',
  store,
})
class SetpointModule extends VuexModule {
  tasqSetpointData: any = {};

  adminSetpointData: any = [];

  adminSetpointDescriptions: any = [];

  @Mutation
  setSetpointDataForTasq(data): void {
    this.tasqSetpointData = data;
  }



  @Mutation
  setAdminSetpointDataDescription(descriptions): void {
    this.adminSetpointDescriptions = descriptions;
  }


  @Mutation
  setAdminSetpointDataForTasq(data): void {
    this.adminSetpointData = data;
  }


  @Action
  async getSetpointDataForTasq({
    tasq,
    dataLength,
    wellName,
  }) {
    const fromTime = new Date(new Date().getTime() - dataLength * 86400000).toISOString().slice(0, -5);
    const toTime = new Date().toISOString().slice(0, -5);

    try {
      const { data: { get_setpoint_rec: { dataset } } } = await setpointApolloClient.query({
        query: getSetpointData,
        variables: {
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
          nodeid: wellName,
          from_time: fromTime,
          to_time: toTime,
          time_var: 'ModifiedTime',
          predictionid: tasq.id,
        },
      });
      const parsedDataset = jsonParse(dataset);
      const { plot } = parsedDataset[0];
      const setpointData = {} as any;
      setpointData.labels = plot.dataset['Tubing Pressure'];
      setpointData.casing = plot.dataset['Casing Pressure'];
      setpointData.static = plot.dataset['Static Pressure'];
      setpointData.loadFactor = plot.dataset['Load Factor'];
      [, setpointData.mainData] = plot.dataset.Value;
      [,, setpointData.lowerData] = plot.dataset.Value;
      [setpointData.upperData] = plot.dataset.Value;
      setpointData.currentPoint = plot.dataset.Current;
      setpointData.recommendedPoint = plot.dataset.Recommend;
      setpointData.uplift = (plot.dataset.perc_uplift * 100).toFixed();

      this.setSetpointDataForTasq(setpointData);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async getSetpointV3DataForTasq({

    wellName,
  }) {
    try {
      const adminSetpointData: any = [];
      const adminSetpointDescriptions: any = []
      const { data: { get_cycle_table: setpoint } } = await setpointApolloV3Client.query({
        query: getSetpointV3CycleTable,
        variables: {
          operator: 'SWN',
          nodeid: 'ROBINSON SOUTH 1-6H',
        },
      });
      const setpointData = (JSON.parse(setpoint.cycles));

      // console.log(setpointData)

      if (setpointData) {
        if (setpointData.pressures) {
          Object.keys(setpointData.pressures).forEach((key) => {
            const updatedData = setpointData.pressures[key].map((data) => ({
              Date: data.Date,
              Open: data.open,
              Arrival: data.arrival,
              Close: data.close,
              Low: data.open,
              High: data.open,
              Volume: data.open,
            }));
            adminSetpointDescriptions.push(`pressure(${key})`)
            adminSetpointData.push({
              name: `pressure(${key})`,
              data: updatedData,
              yxis: 'left',
              type: 'cycles',
            });
          });
          // console.log(adminSetpointData)
        }
        if (setpointData.duration) {
          Object.keys(setpointData.duration).forEach((key) => {
            const updatedData = setpointData.duration[key].map((data) => ({
              Date: data.Date,
              Open: data.open,
              Arrival: data.arrival,
              Close: data.close,
              Low: data.open,
              High: data.open,
              Volume: data.open,
              AfterFlow: data.afterflow
            }));

            adminSetpointDescriptions.push(`duration(${key})`)

            adminSetpointData.push({
              name: `duration(${key})`,
              data: updatedData,
              yxis: 'left',
              type: 'cycles',
            });
          });
         
        }

        if (setpointData.flow) {
          Object.keys(setpointData.flow).forEach((key) => {
            const updatedData = setpointData.flow[key].map((data) => ({
              Date: data.Date,
              Open: data.close,
              Arrival: data.close,
              Close: data.close,
              Low: data.close,
              High: data.close,
              Volume: data.close,
            }));

            adminSetpointDescriptions.push(`flow(${key})`)

            adminSetpointData.push({
              name: `flow(${key})`,
              data: updatedData,
              yxis: 'left',
              type: 'cycles',
            });
          });
         
        }
        // console.log(adminSetpointData)
        this.setAdminSetpointDataForTasq(adminSetpointData)
        this.setAdminSetpointDataDescription(adminSetpointDescriptions)
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export default getModule(SetpointModule);
